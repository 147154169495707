import { ProductDetail } from '@api/catalog';

import ProductCard from '@components/ProductCard';
import Carousel, { CarouselProps } from '@components/controls/Carousel';

import { scale } from '@scripts/gds';

const ProductCarousel = ({
    link,
    products,
    title,
    lazy,
    type,
}: {
    link?: string;
    products?: ProductDetail[];
    title?: string;
    lazy?: boolean;
    type?: CarouselProps['type'];
}) => {
    return products ? (
        <Carousel
            type={type || "products"}
            title={title}
            navigation
            link={link}
            lazy={lazy}
            css={{ maxWidth: scale(162), margin: '0 auto' }}
        >
            {products.map(item => (
                <Carousel.Item key={item.id}>
                    <ProductCard isSlider productItem={item} />
                </Carousel.Item>
            ))}
        </Carousel>
    ) : null;
};

export default ProductCarousel;
